.whatIsSmartlope {
  background: #ff075c;
  color: white;
}

.whatIsSmartlope h3 {
  color: white;
}

.panels {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel {
  color: white;
  border-radius: 8px;
  margin-top: 32px;
  padding: 11px;
}

.panel img {
  margin-bottom: 4px;
}

.panel p {

}

.whatIsSmartlope .button {
  margin-top: 32px;
  background: #ffe4ea;
  color: #fb3960;
}

@media only screen and (min-width : 1224px) {
  .panels {
    flex-direction: row;
  }
  .panel {
    width: 350px;
  }
  .whatIsSmartlope.contentSection p {
    width: auto;
  }
}
