.form {
  background-color: white;
  background-image: url('./white_illustration.svg');
  background-size: 100%;
  background-position-y: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 200px 0;
  text-align: center;
}

.outerFormContainer {

}

.additionalBackground {
  display: none;
}

.form h2 {
  margin-bottom: 24px;
}

.form p small {
  font-size: 14px;
  font-style: italic;
}

.form .formContainer {
  margin: 24px 0 0 0;
  width: 100%;
}

.form .formContainer.withError input[type="email"]{
  border-color: #ff075c;
  background-color: rgba(255, 7, 92, 0.03)
}

.form input {
  margin-bottom: 12px;
}

.form button:disabled {
  opacity: .65;
}

@media only screen and (min-width : 1224px) {
  .form {
    background: linear-gradient(-180deg, rgb(244, 246, 249) 0%, rgb(255, 225, 231) 100%);
    padding: 0;
    position: relative;
    overflow: hidden;
  }

  .outerFormContainer {
    width: 1104px;
    margin: 0 auto;
    background: url('./illustration2.svg') no-repeat 100% 50%;
    padding-bottom: 42px;
    z-index: 1;
  }

  .outerFormContainer .description {
    text-align: left;
  }

  .outerFormContainer .description h2 {
    font-size: 36px;
    font-weight: 600;
  }

  .form section {
    width: 394px;
    padding: 0;
  }
  .form p {
    width: auto;
  }
  .form input,
  .form button {
    width: 400px;
  }
  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .additionalBackground {
    display: block;
    background: white;
    height: 155px;
    width: 110vw;
    border-radius: 100% 0 0 0;
    position: absolute;
    bottom: 0;
    margin: 0 auto;
  }
}
