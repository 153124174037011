.alreadyRegistered {
  margin-top: 24px;
  background: url("./images/hero_background.svg") 100% 0 no-repeat;
  min-height: 200px
}

.alreadyRegistered p {
  font-size: 14px;
  max-width: 50%;
  line-height: 1.75em;
  margin-bottom: 18px;
}

.alreadyRegistered small {
  max-width: 50%;
  display: block;
  font-size: 12px;
}

@media only screen and (min-width : 1200px) {
  .alreadyRegistered {
    width: 600px;
    margin: 0 auto;
  }

}

