footer {
  background: #fb3960;
  color: white;
  font-size: 12px;
  padding: 0 0 24px 0;
  text-align: center;
  line-height: 16px;
}

footer nav {
  padding: 0 12px;
  flex-wrap: wrap;
}

footer img.background {
  margin-bottom: 24px;
}

.white_false footer img.background {
  background-color: #f4f6f9
}

.white_true footer img.background {
  background-color: white;
}

footer p.socialMediaLinksParagraph {
  margin-bottom: 8px;
  position: relative;
}

@media only screen and (min-width : 1224px) {
  footer nav:nth-child(2) {
    margin-top: 0;
  }
}

footer a {
  text-decoration: underline;
  cursor: pointer;
  padding: 0 6px;
}

footer div:first-child {
  margin-bottom: 12px;
}

.socialMediaLinks {
  margin-bottom: 24px;
}

.socialMediaLinks a {
  margin: 0 8px;
}

.otherLinks {
  display: flex;
  margin-bottom: 24px;
  padding: 0 24px;
  justify-content: center;
}

.otherLinks nav {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}

@media only screen and (min-width : 1224px) {
  .otherLinks nav {
    width: 140px;
    max-width: 140px;
  }
}

.otherLinks nav a {
  text-decoration: none;
  color: white;
  padding: 0;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.75em;
}

.legalLinks p {
  margin-bottom: 0;
  font-size: 12px;
}
.legalLinks a {
  margin: 0;
}

footer.footerWithExtraSection {
  background: #fb3960 url("./berlin.svg") no-repeat 50% 0;
}
