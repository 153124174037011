.document {
  padding: 0 25px;

}

.document a {
  color: #ff075c;
}

.document ol li {

}

.document p, .document ol {
  font-size: 14px;
  line-height: 1.75em;
}

.document h2 {
  margin-bottom: 1em;
}

@media only screen and (min-width : 1200px) {
  .document{
    width: 700px;
    margin: 0 auto 56px auto;
  }
}
