.app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #8196ae;
  font-family: "Poppins", sans-serif;
}

.app.white {
  background: white;
}

*.black {
  color: #3f464c;
}

*.centered {
  text-align: center;
}

html, body {
  background: #f4f6f9;
}

section {
  padding: 0 25px;
}

h2 {
  color: #3f464c;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.35em;
  margin: 0;
}

a {
  cursor: pointer;
}

h3 {
  color: #3f464c;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.35em;
  margin: 0 0 16px 0;
}

h4 {
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 16px 0;
}

h5 {
  font-weight: 700;
  font-size: 16px;
  margin: 12px 0;
}

h6 {
  font-weight: 700;
  font-size: 12px;
  line-height: 2em;
  margin: 0;
}

p {
  font-size: 16px;
  line-height: 2em;
  padding: 0;
  margin: 0;
}

p b {
  font-weight: 600;
}

input {
  background: white;
  border-radius: 5px;
  height: 44px;
  width: 100%;
  border: 2px solid #d0dae5;
  color: rgb(129, 150, 174);
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  padding: 0 12px;
  font-size: 16px;
  outline: none;
}

::placeholder {
  color: #cacfd6;
}

button,
.button {
  background: #ff075c;
  border-radius: 8px;
  height: 41px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

ol {
  margin: 0;
  padding: 0 24px;
  font-size: 16px;
  text-align: left;
}

ol li {
  margin-bottom: 16px;
}
ol li::marker {
  font-weight: 600;
}

.page {
  min-height: calc(100vh - 64px - 76px - 44px);
}

.contentSection {
  text-align: center;
  padding: 25px;
}

.buttonsGroup {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width : 1224px) {
  .buttonsGroup .button {
    width: 272px;
  }
}

input[type='text'],
input[type='email'] {
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
