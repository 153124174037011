.cookieBanner {
  position: fixed;
  border-radius: 16px;
  box-shadow: 0 16px 24px -8px rgba(20, 77, 145, 0.14);
  background: white;
  left: 16px;
  right: 16px;
  bottom: 16px;
  padding: 12px;
  display: flex;
}

.cookieBanner p {
  font-size: 14px;
  line-height: 1.35em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 16px;
}

.cookieBanner a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid rgb(208, 218, 229);
  height: 41px;
  font-weight: 600;
  padding: 0 16px;
}

@media only screen and (min-width : 1200px) {
  .cookieBanner {
    width: 700px;
    left: calc((100vw - 700px) / 2);
    display: flex;
    justify-content: space-between;
  }
}
