.confirmation {
  display: flex;
  background: url("./images/hero_background.svg") 100% 0 no-repeat;
}

.confirmation div {
  margin-right: 24px;
  padding-bottom: 26px;
  min-height: 150px;
}

.confirmation small {
  font-weight: 400;
}

.confirmation div h3 {
  max-width: 75%;
}

@media only screen and (min-width : 1200px) {
  .confirmationContainer {
    width: 600px;
    margin: 0 auto;
  }
  .confirmationContainer p {
    font-size: 14px;
    max-width: 75%;
    line-height: 1.75em;
  }
}

@keyframes moveUp {
  from { bottom: 11px; opacity: 0; }
  to { bottom: 54px; opacity: 1; }
}

.rewardsIndicatorContainer {
  display: flex;
}

