.reward {
  padding-bottom: 18px;
}

.reward h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  color: #40464b;
}

.reward p {
  font-size: 12px;
}
