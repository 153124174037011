.navigation {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
  position: relative;
}

.navigation svg {
  cursor: pointer;
}

.navigation .flag {
  position: absolute;
  width: 18px;
  height: 18px;
  background: red;
  right: 12px;
  top: 14px;
  cursor: pointer;
}

.navigation.white_true {
  background: white;
}

.navigation.splashDesktop {
  background: rgb(244, 246, 249);
}
