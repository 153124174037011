.sharing {
  margin-top: 36px;
}

.sharing form {
  display: flex;
  margin-bottom: 8px;
}

form {
  position: relative;
}

form .copied {
  position: absolute;
  background: rgba(70, 70, 70, 0.5);
  color: white;
  border-radius: 8px;
  padding: 9px 14px;
  font-size: 12px;
  right: 0;
  bottom: 11px;
  animation: moveUp 1s;
  opacity: 0;
}

.sharing form .button {
  width: auto;
  margin-left: 8px;
}

.sharing .button {
  margin-bottom: 8px;
  padding: 0 18px;
  box-sizing: border-box;
}

.sharing .button span {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

.sharing .button.email {
  background-color: rgb(185, 185, 185);
}

.sharing .button.email span {
  background-image: url("./images/email.png");
}

.sharing .button.whatsapp {
  background-color: rgb(0, 179, 65);
}

.sharing .button.whatsapp span {
  background-image: url("./images/whatsapp.png");
}

.sharing .button.facebook {
  background-color: rgb(53, 88, 157);
}

.sharing .button.facebook span {
  background-image: url("./images/facebook.png");
}
