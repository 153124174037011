.cityIsYourPlayground {
  background: white;
}

.tags {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 16px 0 24px 0;
}

.tag {
  background: rgb(194, 208, 229);
  border-radius: 11px;
  color: white;
  font-size: 14px;
  padding: 3px 8px;
  margin: 4.5px;
}

@media only screen and (min-width : 1224px) {
  .tags {
    width: 600px;
    margin: 16px auto 24px auto;
  }
}

.contentSection.withExtraBackground {
  padding-bottom: 350px;
  background: url('./berlin.svg') 50% 100% no-repeat;
  background-size: 100%;
}

@media only screen and (min-width : 1524px) {
  .contentSection.withExtraBackground {
    padding-bottom: 500px;
  }

  .contentSection p {
    width: 600px;
    margin: 0 auto;
  }
}
