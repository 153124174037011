.rewardsIndicatorContainer {
  display: flex;
}

.rewardsIndicatorContainer .content {
  flex-grow: 1;
}

.indicator {
  margin-right: 16px;
  width: 26px;
  min-height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.indicator .circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #e2e8f0;
  background: #f4f6f9;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator .circle span {
  display: block;
  background: #e2e8f0;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.indicator .line {
  width: 4px;
  min-height: 100%;
  background: #e2e8f0;
}

.indicator.filled .line {
  background: #fb3960;
}

.indicator.filled .circle {
  border: 2px solid #fb3960;
}

.filled.indicator .circle span {
  background: #fb3960;
}

.indicator.lastOne .line {
  background: none;
}
